import React, { useState } from 'react'
import { useFirebase } from 'gatsby-plugin-firebase'
import { Form, Input, FormGroup, Button, Container, Row, Col } from 'reactstrap'
export type ContactFormProps = {
    uid: string
}
export function ContactForm(props: ContactFormProps) {
    const fb: any = null
    const [firebase, setFirebase] = useState(fb)
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [location, setLocation] = useState()
    const [timeToCall, setTimeToCall] = useState()
    const [message, setMessage] = useState()
    const [submited, setSubmited] = useState(false)
    useFirebase((fb: any) => {
        setFirebase(fb);
    }, [])

    const submitHendler = (event: any) => {
        event.preventDefault()

        const dataPost = {
            name, email, phone, location, timeToCall, message, page: 'contact-us'
        }
        firebase.database().ref(`solutionsconsultants/websiteLeads/${props.uid}`).set(dataPost).then((d: any) => {
            console.log("Sudah Lewat Sini", d)
            setSubmited(true)
        }).catch(
            (err: any) => {
                console.log(err)
            }
        )
        console.log(dataPost)
    }
    console.log("submited", submited, timeToCall)
    let formDisplay = (
        <div style={{ padding: 30, background: '#ffffffe8', marginTop: 50 }} >
            <h5 style={{ textAlign: "center", fontSize: 22 }}  >
                Need help with setting and achieving your financial goals?  We’re here …. only a phone call or message away.  </h5>
            <h5 style={{ textAlign: "center", fontSize: 22 }}  >   Fill out the form below, and we’ll get in touch with you to discuss your needs and goals, and set up an appointment with our team. </h5>

            <Form>
                <div className="form-row" style={{ marginBottom: 15 }}>
                    <div className="col-md-6" >
                        <Input placeholder="Name" type="text" style={{ background: '#fff', fontSize: 16 }} onChange={(evt: any) => {
                            //console.log(evt.target.value)
                            setName(evt.target.value)
                        }}></Input>
                    </div>
                    <div className="col-md-6">
                        <Input placeholder="Email" type="email" style={{ background: '#fff', fontSize: 16 }} onChange={(evt: any) => {
                            //console.log(evt.target.value)
                            setEmail(evt.target.value)
                        }}></Input>
                    </div>
                </div>
                <div className="form-row" style={{ marginBottom: 15 }}>
                    <div className="col-md-6">
                        <Input placeholder="Phone" type="text" style={{ background: '#fff', fontSize: 16 }} onChange={(evt: any) => {
                            //console.log(evt.target.value)
                            setPhone(evt.target.value)
                        }}></Input>
                    </div>
                    <div className="col-md-6" >
                        <Input placeholder="Location" type="text" style={{ background: '#fff', fontSize: 16 }} onChange={(evt: any) => {
                            //console.log(evt.target.value)
                            setLocation(evt.target.value)
                        }}></Input>
                    </div>
                </div>
                <div className="form-row" style={{ marginBottom: 15 }}>
                    <div className="col-md-6">
                        <Input id="exampleFormControlSelect1" placeholder="Best time to call" type="select" style={{ background: '#fff', fontSize: 16 }} onChange={(evt: any) => { 
                            setTimeToCall(evt.target.value)
                        }}>
                            <option value="">Best time to call</option>
                            <option>Morning</option>
                            <option>Afternoon</option>
                            <option>Evening</option>
                            <option>Any Time</option> 
                        </Input>

                    </div>
                    <div className="col-md-6" >

                    </div>
                </div>
                <FormGroup>
                    <label htmlFor="exampleFormControlTextarea1"> Message </label>
                    <Input
                        id="exampleFormControlTextarea1"
                        rows="3"
                        type="textarea"
                        placeholder=" Is there anything specific you would like us to know? "
                        className='form-control' onChange={(evt: any) => {
                            //console.log(evt.target.value)
                            setMessage(evt.target.value)
                        }}
                        style={{ border: "1px solid #e3e3e3", borderRadius: "10px", background: '#fff', fontSize: 16 }}
                    ></Input>
                </FormGroup>
                <div style={{ textAlign: "center" }}>
                    <Button className="btn-round" color="primary" type="button" onClick={(evt) => submitHendler(evt)}>
                        <i className="now-ui-icons ui-1_send"></i> Submit </Button>
                </div>

            </Form>
        </div>
    )
    if (submited) {
        formDisplay = <div style={{ minHeight: 250, background: 'rgba(255, 255, 255, .9)', marginTop: 50, padding: 30, color: '#000', fontWeight: 500 }}>
            <p style={{ color: '#000', fontWeight: 500 }}>Hi {name}, </p>
            <p style={{ color: '#000', fontWeight: 500 }}>Thank you for contacting us at Solutions Consultants. </p>
            <p style={{ color: '#000', fontWeight: 500 }}>Our team will be in touch shortly to chat with you, to see how we can help, to work with you and make your life easier.</p>
            <p style={{ fontWeight: 500 }}>
                Phone <a style={{ fontWeight: 500 }} href="tel: +61 3 9826 6655">+61 3 9826 6655</a> <br />
                <a style={{ fontWeight: 500 }} href="mailto:info@solutionsconsultants.com.au" target="_blank">info@solutionsconsultants.com.au</a><br />
                <a style={{ fontWeight: 500 }} href="https://solutionsconsultants.com.au" target="_blank">https://solutionsconsultants.com.au</a><br />
            </p>
        </div>
    }

    return (
        <section style={{ minHeight: 450, padding: '50px 0' }}>
            <Container>
                <Row className="justify-content-md-center" >
                    <Col md={8}>
                        {formDisplay}
                    </Col>
                </Row>
            </Container>
        </section>
    )
}