import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import { Container, Row, Col, Card, CardImg, CardBody,  CardFooter } from 'reactstrap'
import banner from '../images/contact-bg.jpg'
import { blogtype, blogData } from '../data/blog'
import { Link } from 'gatsby'
import { Router as MyRouter } from "@reach/router"
import { NewsDetail } from "../components/news-detail"
import { RouteComponentProps } from "@reach/router"
interface blogPropsType extends RouteComponentProps {

}
const Blog = (props: blogPropsType) => {

    /*
    const fb: any = null
    const [firebase, setFirebase] = useState(fb) 
    useFirebase((fb: any) => {
        setFirebase(fb);
    }, [])
 */
    console.log("blog props", props)
    const blogDef: blogtype[] = []
    const [blogs, setBlogs] = useState(blogDef)
    const [pageStatus, setpageStatus] = useState('requesting') 
    useEffect(() => {
        if (pageStatus === 'requesting') {
            const datablog: blogtype[] = []
            for (const blg of Object.keys(blogData)) {
                const dtBlg: blogtype = blogData[blg]
                dtBlg.slug = blg
                datablog.push(dtBlg)
            }
            setBlogs(datablog)
            setpageStatus('available')
        }

    }, [pageStatus])
    const isi: any[] = []
    if (pageStatus === 'available') {
        for (const bg of blogs) {
            isi.push(
                <Col key={bg.slug} md={6}>
                    <Card >
                        <CardImg
                            alt={bg.image.altText ? bg.image.altText : "..."}
                            src={bg.image.url}
                            top
                        ></CardImg>
                        <CardBody> 
                                <p color='primary' className="header-font">{bg.title}</p>
                                {bg.shortDesk} 
                        </CardBody>
                        <CardFooter className="text-muted mb-2" style={{ textAlign: 'right', padding: '0 15px', marginTop: 0 }}>
                            <Link className="btn btn-primary"  to={`blog/${bg.slug}`}>Read More</Link>
                        </CardFooter>
                    </Card>
                </Col>
            )
        }
    } else {
        return (
            <p>Loading ...</p>
        )
    }
    console.log("blogs", blogs)

    let display = { display: 'none' }
    const prp: any = props
    if (prp['*'] === '') {
        display = { display: '' }
    }
    return <>
        <div style={display}>
            <Layout
                seo={{ title: "News | Solutions Consultants ", description: 'Solutions Consultants are personal financial planners who assist their clients to build and protect wealth.', keyword: '' }}
                banner={<PPBanner />}
                navStayle="SIMPLE"
                footerType="HOMEPAGE"
                setUid={() => {}} 

            >
                <section style={{ minHeight: 450, padding: '50px 0' }}>
                    <Container>
                        <Row className="justify-content-md-center" >
                            {isi}
                        </Row>
                    </Container>
                </section>

            </Layout>
        </div>

        <MyRouter>
            <NewsDetail path="/blog/:id" />
        </MyRouter>

    </>

}


const PPBanner = () => {
    return (
        <div style={{ backgroundImage: `url(${banner})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <Container>
                <Row style={{ paddingTop: '100px' }}>
                    <Col md={6}>
                        <h2 style={{ marginBottom: 0, textShadow: ' rgb(0, 0, 0) 1px 1px 1px, rgb(49, 49, 49) 3px 3px 5px', color: '#fff', fontWeight: 600 }}>Blog </h2>
                    </Col>
                    <Col style={{ textAlign: 'right' }} md={6}>
                        <a href="/1-on-1-phone-call" className="btn btn-round btn-warning" style={{ fontWeight: 800 }}>1-on-1 Meeting</a>
                    </Col>
                </Row>
                <hr style={{ borderTop: '1px solid rgba(255, 255, 255, 0.58)' }} />
                <Row style={{ marginTop: 50, paddingBottom: 50, textAlign: "center" }}>
                    <Col>
                        <h2 style={{ textShadow: ' rgb(0, 0, 0) 1px 1px 1px, rgb(49, 49, 49) 3px 3px 5px', color: '#fff' }}>

                        </h2>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default Blog

/*
import React from "react"
import { Router as MyRouter } from "@reach/router"
import { NewsDetail } from "./news-detail"

const Blog = () => {
    return (
        <>
            <h1>test</h1>
            <MyRouter>

                <NewsDetail path="/blog/:id" />
            </MyRouter>
        </>
    )
}
export default Blog
*/