export type blogImage = {
    url: string,
    altText?: string
}
export type blogtype = {
    title: string,
    slug?: string,
    metaTitle?: string
    metaDescription?: string
    keyword?: string
    image: blogImage
    content: string
    shortDesk: string
}

export const blogData: { [slug: string]: blogtype } = {
    'the-never-ending-noise-of-finance': {
        title: 'The never-ending noise of Finance',
        metaTitle: 'The never-ending noise of Finance',
        metaDescription: 'The never-ending noise of Finance',
        keyword: 'The never-ending noise of Finance',
        image: {
            url: 'https://firebasestorage.googleapis.com/v0/b/geonet-me.appspot.com/o/cash-flow.jpg?alt=media&token=bdc07c28-a669-4145-8a28-7fae5cd63e99',
        }, 
        shortDesk: 'When it comes to finance our world is full of noise.  Noise that is vying for your dollar- not your attention, your dollar.  Banks who offer low deposit rates...',
        content: `
        <p>When it comes to finance our world is full of noise.  Noise that is vying for your dollar- not your attention, your dollar.  Banks who offer low deposit rates and then lend to others at a higher rate, insurance companies who promise you cheap cover without taking your financial situation into consideration and potentially leaving you and your family exposed, superannuation funds telling us they have made this much but follow it up with “past performance is not an indication of future performance” , Real Estate agents with their wonderful stories of property being the ever winning strategy and even if the value of the investment property decreases the rent never will, and so on.</p>
<p> We have financial products forced upon us wherever we go and this creates confusion because we actually haven’t stopped to think what is it that we really want.  What do you really want?  It’s a simple question to ask but its very difficult to answer. </p>
<p>So we focus on creating value for our clients by helping them find answers to their questions therefore empowering them with their decision process rather than trying to force products on them.  Sure, products are important, but they mean absolutely nothing if they are not supporting the goals and objectives we are helping our clients achieve.</p>
<p>We pay very strong attention to our clients, and make sure they are paying attention to their own needs and not falling into fixed ideas of how to “get ahead” forced on them through all the constant noise mentioned above.  We help our clients discover what is fundamentally important to them and create a solid financial base around those fundamentals, only then do we move onto using tools which will help to achieve their goals and objectives.</p>
<p>If you’re interested in looking at your financial position from a different perspective, give us a call- we would love to hear your story</p>
        `

    },
    'covid-19-and-why-you-need-a-financial-planner' : {
        title: "Covid 19 and Why You Need a Financial Planner",
        image: {
            url: 'https://firebasestorage.googleapis.com/v0/b/geonet-me.appspot.com/o/Covid%2019%20Why%20You%20Need%20a%20Financial%20Planner.jpg?alt=media&token=477ceaa0-b607-4ae4-a328-e95867a1180a',
        },
        shortDesk: 'Now that Covid-19 has wreaked havoc across the world, you’re probably ready to work on your financial planning. Don’t worry, you’re not alone.',
        content: `
        <p>Do you ever take a moment to think about your future? Have you spared any time or income to prepare for retirement? Do you have a plan or portfolio in place for investing and building wealth? </p>
        <p>Now that Covid-19 has wreaked havoc across the world, you’re probably ready to work on your financial planning. Don’t worry, you’re not alone.</p>
        <p>Despite earning lucrative salaries and working since their early 20s, a majority of FIFO workers often find themselves in financial trouble, even before the global pandemic hit.</p>
        <p>Marc Rummel and his team at Solutions Consultants specialise in building financial plans for miners, with an understanding of the unique circumstances that you are in. This includes the current virus crisis.</p>
        <p>In regards to financial panic concerning Covid-19, Marc asserts "this is why we always focus on diversification rather than performance, because you never know what’s around the corner."</p>
        <p>Below, Rummel and a few FIFO workers and Solutions clients share why financial planning is vital, and why spending a bit of time to manage your finances now will protect you and your family in the future.</p>
        
        <p style="font-weight: 800">Cycles and uncertainty. </p>
        <p>The resource industry is notoriously cyclical. Subject to sudden booms or busts, the Covid-19 crisis really underscores the volatile nature of this industry. As the markets revolve, there are seasonal down periods and price cutting that can be predicted and planned for, but market crashes, mine closures, and now widespread viruses, are always a possibility causing heavy effects on the workforce and wages. The rapid Covid-19 disruption has no doubt caused turbulent fluctuations among key commodities in the mining sector - and we can help you use this information to shift your investments and retirement funds.</p>
        
        <p style="font-weight: 800">“Live in the now” </p>
        <p>“Mining is one of the higher paid jobs around but because of this saving for the future sometimes becomes lost in the desire to live out your dreams of better cars, bikes, boats, houses and holidays,” said Rahly. The instant gratification of rewards and spending could put you at risk in the future. The impacts of Covid-19 has caused hundreds of thousands of employers to reduce work hours or cut jobs completely - don’t let that kind of potential setback affect you! Start spending your money smarter, asap.</p>
        
        <p  style="font-weight: 800">No time for planning. </p>
        <p>The long shifts are the top reason that most FIFO workers credit for their lack of financial planning. “Working long hours and shift work means that rest and relaxation is most likely foremost on your mind on days off and not your financial future” said Kev Rahley. Rummel added, “Miners just don’t have time to look after all their affairs while off shift. Finance requires a lot of attention so that it works together with you towards your objectives and goals.”</p>
        
        <p  style="font-weight: 800">No finance education. </p>
        <p>An obvious observation is that you don’t know what you don’t know, and the world of financial planning is cluttered with excess information, noise and jargon. Peter Jackson commented, “I don't have a background in finance so it's not easy for me to understand the ins and outs of investments.” Many miners have never experienced a full market cycle and might feel that the Covid-19 impact on their current investments and superannuation feel like the end of the world. The bigger picture is that you have decades ahead of you. We will help put things in perspective and show you that your finances are long-term investments - not just sources of emergency funds.</p>
        
        <p  style="font-weight: 800">Dangerous, risky work. </p>
        <p>“Although the mining industry is safer than before, it is still difficult and dangerous and requires full attention to prevent accidents and this requires mental energy, which distracts a worker from other commitments in life,” said Rummel. Furthermore, while rare, the grim reality is that accidents do happen. While most FIFO workers know this is the nature of the job, few are prepared with a financial safety net. </p>
        
        <p  style="font-weight: 800">Hard labour takes its toll. </p>
        <p>“I wanted to retire early due to the heavy workload as a Boilermaker/maintenance engineer,” said Rahley. A career in mining, spanning a few decades will no doubt have drastic everlasting effects on the body. For most miners, working through their 50s and 60s, is not possible. Could you retire early if you needed or wanted to? Now that the risk of exposure to Covid-19 is so prevalent and family time has never felt more precious, it would definitely be worth the effort to plan for early retirement. </p>
        
          
        <p>Solutions Consultants keeps it simple. They help miners build a financial plan starting with identifying what’s important to you and protecting those assets. Financial planning can add levity and security, so that you know you and your family are protected.</p>
        
        <p>“Having dealt with miners over the years, we understand time is precious, so we are efficient with communication and delivery of service with our mining clients,” said Rummel.</p>
        
        <p>With the current Covid-19 global pandemic adding even more uncertainty, now is the time to have a professional walk alongside you. </p>
        
        `
    }
    
}

