import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from "@reach/router"

import Layout from './layout'
import banner from '../images/contact-bg.jpg'
import { Container, Row, Col } from 'reactstrap'
import { blogData, blogtype } from '../data/blog'
import { StringToHTML } from './GlobalComponent'
import { Testimonials } from './testimonials'
import { ContactForm } from './contact-form'

export interface NewsDetailProps   extends RouteComponentProps {
    path: string
}
export function NewsDetail(props: NewsDetailProps) {

    const blogDef: blogtype = {} as any
    const prp: any = props
    const id = prp.id
    const [blog, setBlog] = useState(blogDef)
    const [pageStatus, setPageStatus] = useState("requesting")
    console.log("path", props)
    useEffect(() => {
        if (pageStatus === 'requesting') {
            setBlog(blogData[id] as any)
            setPageStatus('available')
        }
        
    }, [id, pageStatus])
    console.log("Blog", blog)
    if (pageStatus === 'requesting') {
        return <h1>Loading..</h1>
    }
    const [uid, setUid] = useState("")

    return (
        <>
           <Layout
            seo={{ title: blog.metaTitle ? blog.metaTitle : blog.title, description:  blog.metaDescription ? blog.metaDescription : blog.title, keyword:  blog.keyword ? blog.keyword : blog.title }}
            banner={<PPBanner title={blog.title}/>}
            navStayle="SIMPLE"
            footerType="HOMEPAGE"
            setUid={(uid: string) => setUid(uid)}
        > 
            <section style={{ minHeight: 450, padding: '50px 0' }}>
                <Container>
                    <Row className="justify-content-md-center" >
                        <Col md={12}>
                            <img src={blog.image.url} alt={blog.image.altText ? blog.image.altText  : '' } style={{width: '50%', float: "left", marginRight: 30}} />
                            <div dangerouslySetInnerHTML={StringToHTML(blog.content)} />
                        </Col>
                    </Row>
                </Container>
                <Testimonials colButton={true} />
                <ContactForm uid={uid} />
            </section>
            
        </Layout>
        </>
    )
}


const PPBanner = (props: { title: string }) => {
    return (
        <div style={{ backgroundImage: `url(${banner})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <Container>
                <Row style={{ paddingTop: '100px', paddingBottom: 30 }}>
                    <Col md={8}>
                        <h2 style={{ marginBottom: 0, textShadow: ' rgb(0, 0, 0) 1px 1px 1px, rgb(49, 49, 49) 3px 3px 5px', color: '#fff', fontWeight: 600 }}>{props.title} </h2>
                    </Col>
                    <Col style={{ textAlign: 'right' }} md={4}>
                        <a href="/1-on-1-phone-call" className="btn btn-round btn-warning" style={{ fontWeight: 800 }}>1-on-1 Meeting</a>
                    </Col>
                </Row>
                 
            </Container>
        </div>
    )
}